export const faqItems = [
  {
    id: 'faq-1',
    title: 'Onze school heeft geen beheerder. Wat nu?',
    text: '<p>Het kan voorkomen dat je school geen beheerder heeft, omdat deze niet meer werkzaam is voor de school of zijn/haar account heeft verwijderd. In dit geval kun je het beste een e-mail sturen naar <a class="link-main">info@mediamasters.nl.</a></p><br/><p>Vermeld de schoolnaam, plaats en het e-mailadres van het Mijn MediaMasters-account van degene op school die de nieuwe beheerder voor MediaMasters wil worden. Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
  {
    id: 'faq-2',
    title: 'Ik wil mijn e-mailadres wijzigen/mijn e-mailadres wordt niet herkend. Wat nu?',
    text: '<p>Vermeld de schoolnaam, plaats en het e-mailadres van het Mijn MediaMasters-account van degene op school die de nieuwe beheerder voor MediaMasters wil worden. Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
  {
    id: 'faq-3',
    title: 'Wat is de tijdsduur van MediaMasters?',
    text: '<p>Vermeld de schoolnaam, plaats en het e-mailadres van het Mijn MediaMasters-account van degene op school die de nieuwe beheerder voor MediaMasters wil worden. Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
  {
    id: 'faq-4',
    title: 'Ik ben mijn wachtwoord vergeten of kwijt. Wat nu?',
    text: '<p>Vermeld de schoolnaam, plaats en het e-mailadres van het Mijn MediaMasters-account van degene op school die de nieuwe beheerder voor MediaMasters wil worden. Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
]
