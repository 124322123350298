<template>
  <div>
    <!-- Category Box -->
    <div class="categories-wrapper">
      <CategoryBox v-for="(category) in toShow"
                   :key="category.id"
                   :text="category.displayName"
                   :icon-active="category.iconActive"
                   :icon-inactive="category.iconInactive"
                   :active="category.id === activeCategoryId"
                   :category-id="category.id"
                   :on-category-click="toggleCategory"
                   class="faq-category"/>
    </div>

    <!-- When/If a box is active, a list of FAQ accordions should appear below. -->
    <div v-if="activeCategory" class="faq-items">
      <p class="page-section-title category-title">{{ activeCategory.displayName }} vragen</p>
      <AccordionItem v-for="faq in activeCategory.questions"
                     :key="faq.id"
                     :title="faq.question"
                     :text="faq.answer"
                     class="faq-item"/>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import CategoryBox from '@/components/elements/faq/CategoryBox'
import AccordionItem from '@/components/elements/accordion/AccordionItem'
import { computed } from '@vue/runtime-core'

export default {
  name: 'FaqCategories',
  components: {
    AccordionItem,
    CategoryBox
  },
  props: {
    categories: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const toShow = computed(() => {
      const show = {}
      for (const category in props.categories) {
        if (props.categories[category].questions && props.categories[category].questions.length > 0) {
          show[category] = props.categories[category]
        }
      }
      return show
    })
    // TODO: When the active category changes, any open accordions should close.
    const activeCategoryId = ref(-1)

    function toggleCategory(categoryId) {
      // If the active category is the clicked item, there should be no active category.
      activeCategoryId.value = isCategoryActive(categoryId) ? -1 : categoryId
    }


    const activeCategory = computed(() => props.categories[activeCategoryId.value])

    /** Gets if a category item is active based on a specified ID.
     * @param {String} id - The id of the category item.
     * @return {Boolean} - Whether the category item is active or not.
     */
    function isCategoryActive(id) {
      return activeCategoryId.value.id === id
    }

    return {
      activeCategory,
      toggleCategory,
      activeCategoryId,
      toShow
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.categories-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.faq-category {
  &:not(:last-child) {
    margin-bottom: rem(32);
    margin-right: rem(39);
  }
}

.category-title {
  margin-bottom: rem(24);
}

.faq-items {
  max-width: rem(867);
}

.faq-item {
  &:not(:last-of-type) {
    margin-bottom: rem(12);
  }
}

/** Active **/

/** **/
</style>
