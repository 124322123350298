<template>
  <div :class="{'active-category': active}" class="category-box" @click="onCategoryClick(categoryId)">
    <!-- Category icon -->
    <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
    <InlineSvg :src="active ? iconActive : iconInactive" class="icon" :class="{'active': active}" alt="Category icon" />
    <!-- <img :src="active ? iconActive : iconInactive" class="icon" alt="Category icon"/> -->
    <p class="text">{{ text }}</p>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'CategoryBox',
  components: {
    InlineSvg
  },
  props: {
    iconActive: {
      type: String,
      required: true
    },
    iconInactive: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    onCategoryClick: {
      type: Function,
      required: true
    },
    categoryId: {
      type: Number,
      required: true
    },

    // Represents whether the category is active or not.
    active: {
      type: Boolean,
      required: true
    },
  },
  setup() {
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

$category-text-color: #A8ACC3;

.category-box {
  @include hover-active-pointer();
  align-items: center;
  background-color: white;
  border-radius: rem(10);
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: rem(171);
  justify-content: center;
  width: rem(256);

  .icon {
    height: auto;
    margin-bottom: rem(28);
    width: rem(50);
    filter: grayscale(100%);

    &:not(.active) {
      //opacity: 70%;
      &:deep(path, :not(.ignore)) {
        fill: #8287A9;
      }
      &:deep(.ignore) {
        fill: white;
      }
    }
  }

  .text {
    color: $category-text-color;
    font-size: rem(20);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(24);
  }
}

/** Active **/
.active-category {
  background-color: var(--pink_main);

  .text {
    color: white;
  }
}
/** **/
</style>
