<template>
  <div class="content-container">
    <PageTitle :title="$t('HELP.TITLE')" class="page-title"/>

    <!-- Contact Section -->
    <h1 class="page-section-title">{{ $t('HELP.CONTACT_TITLE') }}</h1>
    <p class="page-section-text section-text">
      {{ $t('HELP.CONTACT_TEXT') }} <a class="link-main" href="mailto:info@mediamasters.nl">info@mediamasters.nl</a>
    </p>

    <!-- Top 10 FAQ's Section -->
    <h1 class="page-section-title section-title">{{ $t('HELP.TOP', { number: 5 }) }}</h1>
    <div class="faq">
      <AccordionItem v-for="item in topTen"
                     :key="item"
                     :title="item.question"
                     :text="item.answer"
                     class="faq-item"/>
    </div>

    <!-- FAQ Categories Section -->
    <!-- The FAQ's are organised in categories. -->
    <h1 class="page-section-title section-title">{{ $t('HELP.CATEGORIES') }}</h1>
    <FaqCategories
      v-if="categories && Object.keys(categories).length !== 0"
      :categories="categories"/>

  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import AccordionItem from '@/components/elements/accordion/AccordionItem'
import { faqItems } from '@/utils/data/faqItems'
import FaqCategories from '@/components/elements/faq/FaqCategories'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
import { GET_QUESTIONS_PAGE, GET_FAQ_CATEGORIES } from '@/store/modules/teacherPortal/actions'
export default {
  name: 'Help',
  components: {
    FaqCategories,
    AccordionItem,
    PageTitle
  },
  setup() {
    /** FAQ **/
    const faq = faqItems

    const store = useStore()

    const questions = computed(() => store.getters.getQuestionsPage || {})



    /** FAQ - Categories **/

    const categoryMetaData = [
      {
        iconInactive: require('../../assets/icons/icn_technology.svg'),
        iconActive: require('../../assets/icons/icn_technology.svg')
      },
      {
        iconInactive: require('../../assets/icons/ic_questions_mediamasters.svg'),
        iconActive: require('../../assets/icons/ic_questions_mediamasters.svg')
      },
      {
        iconInactive: require('../../assets/icons/ic_questions_technic.svg'),
        iconActive: require('../../assets/icons/ic_questions_technic_selected.svg')
      },
      {
        iconInactive: require('../../assets/icons/ic_questions_other.svg'),
        iconActive: require('../../assets/icons/ic_questions_other_selected.svg')
      }
    ]

    const topTen = computed(() => questions.value.topTen || {})
    const categories = computed(() => addMetaData(questions.value.categories))


    function addMetaData(categories) {
      const onlyCategories = {}
      for (const key in categories) {
        const value = categories[key]
        const metaData = categoryMetaData[value.id - 1]
        onlyCategories[value.id] = {
          id: value.id,
          displayName: value.displayName,
          subtitle: value.displayName + ' vragen',
          iconActive: metaData.iconActive,
          iconInactive: metaData.iconInactive,
          questions: value.questions
        }
      }
      return onlyCategories
    }


    store.dispatch(GET_QUESTIONS_PAGE)
    store.dispatch(GET_FAQ_CATEGORIES)
    // TODO: Convert to translation strings. We can also create a separate JS file with this data, since it is purely static

    return {
      /** FAQ **/
      faq,

      /** FAQ - Categories **/
      categories,
      topTen,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.section-text {
  margin-bottom: rem(32);
}

.section-title {
  margin-bottom: rem(24);
}

/** FAQ **/
.faq {
  margin-bottom: rem(32);
  max-width: rem(867);
}

.faq-item {
  &:not(:last-of-type) {
    margin-bottom: rem(12);
  }
}

/** **/

</style>
