<template>
  <!-- TODO: Maybe it is desired to click on the entire section in order to expand. -->
  <div >

    <!-- Accordion tab -->
    <div :class="{'accordion-open': isExpanded}" class="accordion-item-box" @click="toggleItem()" >

      <!-- The accordion dot -->
      <div class="item-dot"></div>

      <!-- The accordion title -->
      <p class="item-title"
         :class="{ 'item-title-selected' : isExpanded}">{{ title }}</p>

      <!-- Expand toggle -->
      <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
      <img v-if="
             !isExpanded"
           src="../../../assets/icons/icn_add.svg"
           class="item-toggle"
           alt="Toggle">
      <div v-else class="item-toggle fold-in">

      </div>
    </div>

    <!-- Expanded area -->
    <transition name="slide">
      <!-- TODO: We could potentially make this a slot. -->
      <div v-if="isExpanded" class="item-expanded">
        <pre class="text" v-html="text"></pre>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'

export default {
  name: 'AccordionItem',
  props: {
    // This is a basic setup. It can be configured according to our needs.
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const isExpanded = ref(false)

    /** Toggle **/
    function toggleItem() {
      isExpanded.value = !isExpanded.value
    }

    /** Expanded area **/

    return {
      /** Toggle **/
      toggleItem,

      /** Expanded area **/
      isExpanded,
    }
  },
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

$dot-color: var(--purple_light);

/** Toggle **/
.accordion-item-box {
  @include hover-active-pointer();
  align-items: center;
  border-radius: rem(10);
  background-color: white;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  padding: rem(20) rem(24);
  position: relative;
  transition: 0.2s ease-in-out;
}

.accordion-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.item-dot {
  background-color: $dot-color;
  border-radius: 50%;
  flex-shrink: 0;
  height: rem(10);
  margin-right: rem(20);
  width: rem(10);
}

.item-toggle {
  @include position(absolute, $top: 50%, $right: rem(24));
  @include hover-active-pointer();
  height: rem(14);
  transform: translateY(-50%);
  width: rem(14);

  &.fold-in {
    background: var(--pink_main);

    width: rem(10);
    height: rem(2);

    border-radius: rem(1);
  }
}

.item-title {
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(19);
}

.item-title-selected{
  font-weight: 1000;
}

/** Expanded area **/
.item-expanded {
  border-bottom-left-radius: rem(10);
  border-bottom-right-radius: rem(10);
  background-color: white;
  border-top: rem(1) solid #BDC1CB;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: rem(16) rem(54);
}

.text {
  margin: 0;
  font-size: rem(16);
  font-family: inherit;
  letter-spacing: 0;
  line-height: rem(22);
  text-align: left;
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

/** Transition **/
.slide-enter-active,
.slide-leave-active {
  transition: 0.1s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  height: 0;
  //opacity: 0;
  padding-bottom: 0;
  padding-top: 0;
}
/** **/
</style>
